<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('Roles')"
    @cancel="onCancel('Roles')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'RoleEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'Roles',
      pageHeader: 'Редактирование роли сотрудника',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Роли сотрудников',
          route: { name: 'Roles' },
        },
        {
          text: 'Редактирование роли сотрудника',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      permissions: (state) => state.common.permissions,
    }),

    permissionsList() {
      if (this.form?.permissions === undefined) return [];

      return this.form.permissions.map((item) => ({
        ...item,
        name: this.permissions.data.find(
          (element) => element.id === item.permission
        ).name,
      }));
    },

    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Наименование',
            },
          ],
        },
        {
          tab: 'Разрешения',
          table: {
            caption: 'Разрешения',
            headers: [
              {
                text: 'Разрешение',
                order: '',
                alias: 'name',
              },
              {
                text: '',
                order: '',
                alias: 'actions',
              },
            ],
            items: this.permissionsList,
            key: 'permissions',
            modalFields: [
              {
                type: 'select',
                key: 'permission',
                label: 'Разрешение',
                list: this.permissions.data,
              },
            ],
            showCreateButton: true,
            showDeleteButton: true,
          },
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const permissions = this.getCommonList({ name: 'Permissions' });

    Promise.all([permissions]).then(() => {
      this.getItem({ name: this.controllerName, id: this.id })
        .then(() => {
          this.form = this.lodash.cloneDeep(this.item.data);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
